import React, { useState, useEffect } from "react";
import { useUpdate, useNotify, useRefresh } from "react-admin";
import { Switch, FormControlLabel } from "@mui/material";
import { SwitchConfirmationModal } from "../components/SwitchConfirmationModal";

const randomSession = `admin-${
  import.meta.env.VITE_APP_ENV
}-session-${Date.now().toString()}`;

const EtlReadySwitch = ({ pipeline, organizationID }) => {
  const etlReady = pipeline?.etlReady || false;
  const [update, { isLoading: isUpdating }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  console.log("etlReady", etlReady);
  console.log("pipeline", pipeline);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [desiredValue, setDesiredValue] = useState(etlReady);

  useEffect(() => {
    setDesiredValue(etlReady);
  }, [etlReady]);

  const handleEtlReadyChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newValue = !etlReady;
    setDesiredValue(newValue);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmChange = async () => {
    console.log("desiredValue", desiredValue);
    await update(
      "pipelineForOrganization",
      {
        id: organizationID,
        data: {
          input: {
            etlReady: desiredValue,
          },
        },
        meta: {
          tracing: {
            pageID: "admin.showOrganization.etlReady",
            sessionID: randomSession,
          },
        },
      },
      {
        onSuccess: () => {
          notify("ETL Ready status updated successfully", { type: "info" });
          refresh();
          setIsModalOpen(false);
        },
        onError: (error) => {
          notify(`Error updating ETL Ready status: ${error.message}`, {
            type: "warning",
          });
          setIsModalOpen(false);
        },
      }
    );
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={etlReady}
            // onClick={(event) => {
            //   event.preventDefault();
            //   event.stopPropagation();
            // }}
            onChange={handleEtlReadyChange}
            disabled={isUpdating}
            name="etlReadySwitch"
          />
        }
        label="ETL Ready"
      />
      <SwitchConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmChange}
        label={`Confirm ${desiredValue ? "enabling" : "disabling"} ETL Ready`}
        message={`Are you sure you want to ${
          desiredValue ? "enable" : "disable"
        } ETL Ready?`}
        confirmWord="confirm"
        irreversible={false}
      />
    </>
  );
};

export default EtlReadySwitch;
