import {
  Create,
  required,
  SimpleForm,
  TextInput,
  useCreate,
  useNotify,
  useRedirect,
} from "react-admin";

export const OrganizationCreate = (props: any) => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSubmit = async (data: any) => {
    const randomSessionID = `admin-${
      import.meta.env.VITE_APP_ENV
    }-session-${Date.now().toString()}`;

    try {
      await create(
        "organizations",
        {
          data: {
            input: {
              publicID: data.publicID,
              name: data.name,
              email: data.email,
            },
            tracing: {
              sessionID: randomSessionID,
              pageID: `${import.meta.env.VITE_APP_ENV}.organizations.create`,
            },
          },
        },
        {
          onSuccess: () => {
            notify("Organization created successfully");
            redirect("list", "organizations");
          },
          onError: (error) => {
            notify("Error creating organization", { type: "error" });
            console.error("Error creating organization:", error);
          },
        }
      );
    } catch (error) {
      console.error("Error in create function:", error);
    }
  };

  return (
    <Create {...props}>
      <SimpleForm onSubmit={onSubmit}>
        <TextInput
          source="publicID"
          label="PublicID"
          validate={required()}
          fullWidth
        />
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="email" validate={[required()]} fullWidth />
      </SimpleForm>
    </Create>
  );
};
