import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  Create,
  SimpleForm,
  TextInput,
  required,
  Show,
  SimpleShowLayout,
  useCreate,
  useNotify,
  useRedirect,
  Empty,
  Pagination,
  Button,
  useDelete,
  useGetRecordId,
  BooleanInput,
  Edit,
  useUpdate,
  Toolbar,
  SaveButton,
  BooleanField,
} from "react-admin";
import { useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as TextFieldMUI,
} from "@mui/material";

export const IntegrationsList = () => {
  return (
    <List
      empty={<Empty />}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[100]} />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="integrationID"
          label={"integrationID"}
          sortable={false}
        />
        <TextField source="name" sortable={false} />
        {/*<TextField source="publicID" label={"publicID"} sortable={false} />*/}
        {/*<TextField*/}
        {/*  source="creatorAccountID"*/}
        {/*  label={"creatorAccountID"}*/}
        {/*  sortable={false}*/}
        {/*/>*/}
        {/*<TextField source="status" sortable={false} />*/}
        {/*<TextField source="demo" sortable={false} />*/}
        <TextField source="createdAt" sortable={false} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const LabeledField = ({ label, source }: { label: string; source: string }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle2" fontWeight={600} color="text.secondary">
      {label}
    </Typography>
    <TextField source={source} />
  </Box>
);

export const IntegrationShow = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteOne, { isLoading }] = useDelete();
  const notify = useNotify();
  const redirect = useRedirect();
  const recordId = useGetRecordId();

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await deleteOne(
      "integrations",
      { id: recordId },
      {
        onSuccess: () => {
          notify("Integration deleted successfully");
          redirect("/integrations");
        },
        onError: (error) => {
          // @ts-ignore
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }
    );
    setIsDeleteModalOpen(false);
  };

  return (
    <Show>
      <SimpleShowLayout>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
          <Typography variant="h5" gutterBottom>
            Integration Details
          </Typography>
          <Button
            color="error"
            onClick={handleDeleteClick}
            disabled={isLoading}
            label={"Delete"}
          ></Button>
        </Box>
        <Box ml={2} display="flex" flexWrap="wrap">
          <Box flex="1" minWidth="200px" mr={2}>
            <LabeledField label="Name" source="name" />
            <LabeledField label="IntegrationID" source="integrationID" />
            <LabeledField label="Description" source="description" />
          </Box>
          <Box flex="1" minWidth="200px">
            {/*<LabeledField label="Organization ID" source="organizationID" />*/}
            <LabeledField
              label="Generally Available"
              source="generallyAvailable"
            />
          </Box>
          <Box flex="1" minWidth="200px">
            <LabeledField label="Created At" source="createdAt" />
            <LabeledField label="Updated At" source="updatedAt" />
          </Box>
        </Box>
        {/*<Divider sx={{ my: 1 }} />*/}
        {/*<Box mb={3}>*/}
        {/*  <Typography variant="h6" gutterBottom>*/}
        {/*    Members*/}
        {/*  </Typography>*/}
        {/*  <ArrayField source="members.items">*/}
        {/*    <Datagrid bulkActionButtons={false}>*/}
        {/*      <TextField source="account.firstName" label="First Name" />*/}
        {/*      <TextField source="account.lastName" label="Last Name" />*/}
        {/*      <TextField source="account.contactEmail" label="Email" />*/}
        {/*      <TextField source="role" label="Role" />*/}
        {/*      <TextField source="accountID" label="Account ID" />*/}
        {/*    </Datagrid>*/}
        {/*  </ArrayField>*/}
        {/*</Box>*/}
      </SimpleShowLayout>
      <DeleteConfirmationModal
        open={isDeleteModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
      />
    </Show>
  );
};

export const IntegrationCreate = (props: any) => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSubmit = async (data: any) => {
    const randomSessionID = `admin-${
      import.meta.env.VITE_APP_ENV
    }-session-${Date.now().toString()}`;

    try {
      await create(
        "integrations",
        {
          data: {
            input: {
              integrationID: data.integrationID,
              name: data.name,
              description: data.description,
              generallyAvailable: data.generallyAvailable,
            },
            tracing: {
              sessionID: randomSessionID,
              pageID: `${import.meta.env.VITE_APP_ENV}.organizations.create`,
            },
          },
        },
        {
          onSuccess: () => {
            notify("Integration created successfully");
            redirect("list", "integrations");
          },
          onError: (error) => {
            notify("Error creating integration", { type: "error" });
            console.error("Error creating integration:", error);
          },
        }
      );
    } catch (error) {
      console.error("Error in create function:", error);
    }
  };

  return (
    <Create {...props}>
      <SimpleForm onSubmit={onSubmit}>
        <TextInput
          source="integrationID"
          label="IntegrationID"
          validate={required()}
          fullWidth
        />
        <TextInput source="name" validate={required()} fullWidth />
        <TextInput source="description" validate={[required()]} fullWidth />
        <BooleanInput
          name={"generallyAvailable"}
          source="generallyAvailable"
          label="Generally Available"
        />
      </SimpleForm>
    </Create>
  );
};

const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const IntegrationEdit = (props: any) => {
  const recordId = useGetRecordId();
  const [update] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSubmit = async (data: any) => {
    const randomSessionID = `admin-${
      import.meta.env.VITE_APP_ENV
    }-session-${Date.now().toString()}`;

    try {
      await update(
        "integrations",
        {
          data: {
            input: {
              organizationID: recordId,
              name: data.name,
              demo: data.demo,
            },
            tracing: {
              sessionID: randomSessionID,
              pageID: `${import.meta.env.VITE_APP_ENV}.organizations.create`,
            },
          },
        },
        {
          onSuccess: () => {
            notify("integration updated successfully");
            redirect("list", "integrations");
          },
          onError: (error) => {
            notify("Error updating integration", { type: "error" });
            console.error("Error updating integration:", error);
          },
        }
      );
    } catch (error) {
      console.error("Error in update function:", error);
    }
  };

  return (
    <Edit {...props}>
      <SimpleForm onSubmit={onSubmit} toolbar={<UserEditToolbar />}>
        <TextInput name={"name"} source="name" />
        <BooleanInput name={"demo"} source="demo" label="Demo" />
      </SimpleForm>
    </Edit>
  );
};

const DeleteConfirmationModal = ({ open, onClose, onConfirm }: any) => {
  const [confirmInput, setConfirmInput] = useState("");
  const confirmWord = "delete";

  const handleInputChange = (e: { target: { value: string } }) => {
    setConfirmInput(e.target.value.toLowerCase());
  };

  const handleConfirm = () => {
    if (confirmInput === confirmWord) {
      onConfirm();
      setConfirmInput("");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-integration-title"
      aria-describedby="delete-integration-description"
    >
      <DialogTitle id="delete-integration-title">
        Confirm Integration Deletion
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-integration-description">
          Are you sure you want to delete this integration?{" "}
        </DialogContentText>
        <Box my={0.5} fontWeight={"600"}>
          This action is irreversible
        </Box>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 2, mb: 1 }}>
          To confirm deletion, type the word <strong>{confirmWord}</strong>{" "}
          below:
        </Typography>
        <TextFieldMUI
          value={confirmInput}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          placeholder={confirmWord}
          inputProps={{
            "aria-label": 'Confirm deletion by typing "delete"',
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" label="Cancel"></Button>
        <Button
          onClick={handleConfirm}
          color="error"
          disabled={confirmInput !== confirmWord}
          label={"Confirm Deletion"}
        ></Button>
      </DialogActions>
    </Dialog>
  );
};
