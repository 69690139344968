import React, { useEffect, useState } from "react";
import {
  Admin,
  Resource,
  fetchUtils,
  combineDataProviders,
  DataProvider,
} from "react-admin";
import * as Sentry from "@sentry/react";
import { CognitoAuthProvider, Login } from "ra-auth-cognito";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { ChatSessionsList, ChatSessionShow } from "./chatSessions/chatSessions";
import { ragDataProvider } from "./ragDataProvider";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { TicketCreate, TicketsList, TicketsShow } from "./tickets/tickets";
import { i18nProvider } from "./i18nProvider";
import buildGraphQLProvider from "ra-data-graphql";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import buildQuery from "./buildQuery";
import ChatIcon from "@mui/icons-material/Chat";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import PeopleIcon from "@mui/icons-material/People";
import {
  IntegrationCreate,
  IntegrationShow,
  IntegrationsList,
} from "./integrations/integrations";
import { OrganizationsList } from "./organizations/list";
import { OrganizationShow } from "./organizations/show";
import { OrganizationCreate } from "./organizations/create";
import { OrganizationEdit } from "./organizations/edit";
import { AccountsList } from "./accounts/list";
import { AccountShow } from "./accounts/show";
import { generateUrlSafeId } from "./generateUrlSafeId";

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const sourceRequestID =
    operation?.variables?.sourceRequestID || generateUrlSafeId();

  const sessionID = operation?.variables?.sessionID || "";
  const pageID = operation?.variables?.pageID || "";

  if (graphQLErrors) {
    Sentry.withScope((scope) => {
      scope.setTags({
        sourceRequestID,
        sessionID,
        pageID,
      });
      graphQLErrors.forEach(({ message }) => {
        Sentry.captureException(new Error(message));
      });
    });
  }

  if (networkError) {
    Sentry.withScope((scope) => {
      scope.setTags({
        sourceRequestID,
        sessionID,
        pageID,
      });
      Sentry.captureException(networkError);
    });
  }
});

const userPool = new CognitoUserPool({
  UserPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
  ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
});

const authProvider = CognitoAuthProvider(userPool, {
  applicationName: `${
    import.meta.env.VITE_APP_ENV
  } SteelBeam CS Admin Dashboard`,
});

const httpClient = async (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const user = userPool.getCurrentUser();
  if (user) {
    try {
      const session = await new Promise<CognitoUserSession | null>(
        (resolve, reject) => {
          user.getSession(
            (err: Error | null, session: CognitoUserSession | null) => {
              if (err) reject(err);
              else if (session) resolve(session);
              else reject(new Error("Cognito session is null"));
            }
          );
        }
      );
      const idJwt = session?.getIdToken()?.getJwtToken();
      options.headers.set("Authorization", `Bearer ${idJwt}`);
    } catch (error) {
      console.error("Error getting Cognito session:", error);
      Sentry.captureException(error);
    }
  }
  return fetchUtils.fetchJson(url, options);
};

// Graphql data provider for chatSessions and tickets
const simpleRagDataProvider = ragDataProvider(
  `${import.meta.env.VITE_RAG_API_URL}/mongo_proxy`,
  httpClient
) as DataProvider;

export const App = () => {
  const [dataProvider, setDataProvider] = useState<DataProvider | null>(null);

  useEffect(() => {
    const buildProvider = async () => {
      const httpLink = createHttpLink({
        uri: `${import.meta.env.VITE_GRAPHQL_API_URL}/graphql`,
      });
      console.log("GraphQL httpLink:", httpLink);

      const authLink = setContext(async (_, { headers }) => {
        const user = userPool.getCurrentUser();
        if (user) {
          try {
            console.log("Getting Cognito session");
            const session = await new Promise<CognitoUserSession>(
              (resolve, reject) => {
                user.getSession(
                  (err: Error | null, session: CognitoUserSession | null) => {
                    console.log("Cognito session:", session);
                    if (err) reject(err);
                    else if (session) resolve(session);
                    else reject(new Error("Cognito session is null"));
                  }
                );
              }
            );

            const idJwt = session?.getIdToken().getJwtToken();
            const user_attributes = session?.getIdToken()?.decodePayload();

            Sentry.setUser({
              email: user_attributes?.email || "",
              username: user_attributes?.email || "",
              id: user_attributes?.sub || "",
            });

            return {
              headers: {
                ...headers,
                authorization: `Bearer ${idJwt}`,
              },
            };
          } catch (error) {
            console.error("Error getting Cognito session:", error);
            Sentry.captureException(error);
          }
        }
        return { headers };
      });

      const client = new ApolloClient({
        link: errorLink.concat(authLink.concat(httpLink)),
        cache: new InMemoryCache(),
      });

      const graphqlProvider = await buildGraphQLProvider({
        client,
        buildQuery,
      });

      const dataProvider: DataProvider = combineDataProviders((resource) => {
        console.log("Current resource selected:", resource);

        switch (resource) {
          case "chatSessions":
          case "tickets":
            return simpleRagDataProvider;

          case "organizations":
          case "integrations":
          case "pipelineForOrganization":
          case "accounts":
            return graphqlProvider;

          default:
            throw new Error(`Unknown resource: ${resource}`);
        }
      });

      setDataProvider(dataProvider);
    };
    buildProvider();
  }, []);

  if (!dataProvider) return <div>Loading...</div>;

  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      title="SteelBeam Admin"
      loginPage={Login}
    >
      {/*<Resource*/}
      {/*  name="chatSessions"*/}
      {/*  list={ChatSessionsList}*/}
      {/*  hasShow={true}*/}
      {/*  show={ChatSessionShow}*/}
      {/*  icon={ChatIcon}*/}
      {/*/>*/}
      {/*<Resource*/}
      {/*  name="tickets"*/}
      {/*  list={TicketsList}*/}
      {/*  hasShow={true}*/}
      {/*  show={TicketsShow}*/}
      {/*  create={TicketCreate}*/}
      {/*/>*/}
      <Resource
        name="organizations"
        list={OrganizationsList}
        hasCreate={true}
        hasShow={true}
        hasEdit={true}
        show={OrganizationShow}
        create={OrganizationCreate}
        edit={OrganizationEdit}
        icon={CorporateFareIcon}
      />
      <Resource
        name="accounts"
        list={AccountsList}
        // hasCreate={true}
        hasShow={true}
        // hasEdit={true}
        show={AccountShow}
        // create={OrganizationCreate}
        // edit={OrganizationEdit}
        icon={PeopleIcon}
        options={{ label: "Members" }}
      />
      <Resource
        name="integrations"
        list={IntegrationsList}
        hasCreate={true}
        hasShow={true}
        // hasEdit={true}
        show={IntegrationShow}
        create={IntegrationCreate}
        // edit={OrganizationEdit}
        icon={SettingsApplicationsIcon}
      />
    </Admin>
  );
};
