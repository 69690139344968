import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as TextFieldMUI,
  Box,
  Typography,
} from "@mui/material";
import { Button } from "react-admin";

export const SwitchConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  label = "Confirm Action",
  message = "Are you sure you want to proceed?",
  confirmWord = "confirm",
  irreversible = true,
}: any) => {
  const [confirmInput, setConfirmInput] = useState("");
  const handleInputChange = (e: { target: { value: string } }) => {
    setConfirmInput(e.target.value.toLowerCase());
  };

  const handleConfirm = () => {
    if (confirmInput === confirmWord) {
      onConfirm();
      setConfirmInput("");
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">{label}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {message}
        </DialogContentText>
        {irreversible && (
          <Box my={0.5} fontWeight="600">
            This action is irreversible
          </Box>
        )}
        <Typography variant="body2" color="textSecondary" sx={{ mt: 2, mb: 1 }}>
          To confirm, type the word <strong>{confirmWord}</strong> below:
        </Typography>
        <TextFieldMUI
          value={confirmInput}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          placeholder={confirmWord}
          inputProps={{
            "aria-label": `Confirm by typing "${confirmWord}"`,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" label="Cancel"></Button>
        <Button
          onClick={handleConfirm}
          color="error"
          disabled={confirmInput !== confirmWord}
          label="Confirm"
        ></Button>
      </DialogActions>
    </Dialog>
  );
};
