import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
} from "react";
import {
  Datagrid,
  Empty,
  FunctionField,
  List,
  TextField,
  Pagination,
  ShowButton,
} from "react-admin";

export const AccountsList = () => {
  return (
    <List
      empty={<Empty />}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[50, 100]} />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="accountID" label={"AccountID"} sortable={false} />
        <TextField source="firstName" sortable={false} />
        <TextField source="lastName" sortable={false} />
        <FunctionField
          label="Organization Name"
          render={(record: {
            organizations: {
              organization: {
                name: string;
              };
            }[];
          }) =>
            record.organizations && record.organizations[0]?.organization?.name
              ? record.organizations[0].organization.name
              : "-"
          }
        />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
