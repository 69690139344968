import "./sentryClient";
import React from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { App } from "./App";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <div
          style={{
            minWidth: "100vw",
            minHeight: "100vh",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ maxWidth: "700px" }}>
            <h2>You have encountered an error</h2>
            <p>{error?.toString()}</p>
            <div>{componentStack}</div>
          </div>
        </div>
      )}
    >
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
