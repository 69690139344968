export function generateUrlSafeId(bytes = 8) {
  // Generate random bytes using Web Crypto API
  const randomBytes = new Uint8Array(bytes);
  crypto.getRandomValues(randomBytes);

  // Convert to base64
  const base64 = btoa(String.fromCharCode.apply(null, Array.from(randomBytes)));

  // Make URL safe by replacing + with - and / with _
  const urlSafe = base64.replace(/\+/g, "-").replace(/\//g, "_");

  // Remove padding (=) from the end
  return urlSafe.replace(/=+$/, "");
}
