import {
  ArrayField,
  Datagrid,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const LabeledField = ({ label, source }: { label: string; source: string }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle2" fontWeight={600} color="text.secondary">
      {label}
    </Typography>
    <TextField source={source} />
  </Box>
);

const randomSession = `admin-${
  import.meta.env.VITE_APP_ENV
}-session-${Date.now().toString()}`;

export const AccountShow = () => {
  return (
    <Show
      queryOptions={{
        meta: { sessionID: randomSession, pageID: "admin.showAccount" },
      }}
    >
      <SimpleShowLayout>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
          <Typography variant="h5" gutterBottom>
            Member Details
          </Typography>
        </Box>
        <Box ml={2} display="flex" flexWrap="wrap">
          <Box flex="1" minWidth="200px" mr={2}>
            <LabeledField label="First name" source="firstName" />
            <LabeledField label="Last name" source="lastName" />
            <LabeledField label="Email" source="contactEmail" />
            <LabeledField label="Account ID" source="accountID" />
          </Box>
          <Box flex="1" minWidth="200px">
            <LabeledField label="Active" source="active" />
            <LabeledField label="Status" source="status" />
          </Box>
          <Box flex="1" minWidth="200px">
            <LabeledField label="Created At" source="createdAt" />
            <LabeledField label="Updated At" source="updatedAt" />
            <LabeledField label="Closed At" source="closedAt" />
          </Box>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            Organizations
          </Typography>
          <ArrayField source="organizations">
            <Datagrid bulkActionButtons={false}>
              <FunctionField
                label={"Name"}
                render={(record: {
                  organizationID: string;
                  organization: {
                    name: string;
                  };
                }) => {
                  return (
                    <a
                      href={`/#/organizations/${record.organizationID}/show`}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Typography
                        variant={"body2"}
                        sx={{
                          color: "blue",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {record.organization.name}
                      </Typography>
                    </a>
                  );
                }}
              />
              {/*<TextField source="organization.name" label="Organization name" />*/}
              <TextField source="organizationID" label="Organization ID" />
              <TextField source="role" label="Role" />
            </Datagrid>
          </ArrayField>
        </Box>
      </SimpleShowLayout>
    </Show>
  );
};
