import { useState } from "react";
import {
  ArrayField,
  Button,
  Datagrid,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  useCreate,
  useDelete,
  useGetOne,
  useGetRecordId,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress, List, ListItem, ListItemText } from "@mui/material";
import { IntegrationModal } from "./integrationModal";
import { DeleteConfirmationModal } from "./deleteModal";

const LabeledField = ({ label, source }: { label: string; source: string }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle2" fontWeight={600} color="text.secondary">
      {label}
    </Typography>
    <TextField source={source} />
  </Box>
);

const CustomLabeledField = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle2" fontWeight={600} color="text.secondary">
      {label}
    </Typography>
    <Typography variant="subtitle2" fontWeight={400}>
      {value}
    </Typography>
  </Box>
);

const randomSessionID = `admin-${
  import.meta.env.VITE_APP_ENV
}-session-${Date.now().toString()}`;

export const OrganizationShow = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteIntegrationModalOpen, setIsDeleteIntegrationModalOpen] =
    useState(false);
  const [isIntegrationModalOpen, setIsIntegrationModalOpen] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const recordId = useGetRecordId();
  const [deleteOne] = useDelete();

  const {
    data: pipeline,
    isLoading: isLoadingPipeline,
    error,
  } = useGetOne("pipelineForOrganization", {
    id: recordId,
    meta: {
      tracing: {
        pageID: "admin.showOrganization.integrationList",
        sessionID: randomSessionID,
      },
    },
  });

  // Handle Delete Organization
  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await deleteOne(
      "organizations",
      { id: recordId },
      {
        onSuccess: () => {
          notify("Organization deleted successfully");
          redirect("/organizations");
        },
        onError: (error) => {
          // @ts-ignore
          notify(`Error: ${error.message}`, { type: "error" });
        },
      }
    );
    setIsDeleteModalOpen(false);
  };

  // Handle Add Integration
  const handleAddIntegrationClick = () => {
    setIsIntegrationModalOpen(true);
  };

  const handleCloseIntegrationModal = () => {
    setIsIntegrationModalOpen(false);
  };

  // Use useMutation for adding an integration
  const [createPipelineIntegration] = useCreate();

  const handleConfirmAddIntegration = async (integrationID, configValues) => {
    const randomSessionID = `admin-${
      import.meta.env.VITE_APP_ENV
    }-session-${Date.now().toString()}`;

    if (!recordId) return;

    try {
      await createPipelineIntegration(
        "pipelineForOrganization",
        {
          data: {
            input: {
              organizationID: recordId,
              integration: {
                integrationID,
                configuration: configValues,
              },
            },
            tracing: {
              sessionID: randomSessionID,
              pageID: `${
                import.meta.env.VITE_APP_ENV
              }.pipelineForOrganization.create`,
            },
          },
        },
        {
          onSuccess: () => {
            notify("Integration added successfully");
            refresh();
            setIsIntegrationModalOpen(false);
          },
          onError: (error) => {
            notify(`Error adding integration: ${error.message}`, {
              type: "error",
            });
          },
        }
      );
    } catch (error) {
      notify(`Error adding integration: ${error.message}`, { type: "error" });
    }
  };

  const handleRemoveIntegration = async () => {
    try {
      await deleteOne(
        "pipelineForOrganization",
        { id: recordId },
        {
          onSuccess: () => {
            notify("Integration removed successfully");
            refresh();
          },
          onError: (error) => {
            notify(`Error removing integration: ${error.message}`, {
              type: "error",
            });
          },
        }
      );
    } catch (error) {
      notify(`Error removing integration: ${error.message}`, {
        type: "error",
      });
    }
  };

  return (
    <Show>
      <SimpleShowLayout>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
          <Typography variant="h5" gutterBottom>
            Organization Details
          </Typography>
          <Button color="error" onClick={handleDeleteClick} label="Delete" />
        </Box>
        <Box ml={2} display="flex" flexWrap="wrap">
          <Box flex="1" minWidth="200px" mr={2}>
            <LabeledField label="Name" source="name" />
            <LabeledField label="Public ID" source="publicID" />
            <LabeledField label="Demo organization" source="demo" />
          </Box>
          <Box flex="1" minWidth="200px">
            <LabeledField label="Organization ID" source="organizationID" />
            <LabeledField label="Status" source="status" />
            <CustomLabeledField
              label="ETL Ready"
              value={!isLoadingPipeline ? String(pipeline?.etlReady) : ""}
            />
            <CustomLabeledField
              label="Infrastructure Status"
              value={
                !isLoadingPipeline ? String(pipeline?.infrastructureStatus) : ""
              }
            />
          </Box>
          <Box flex="1" minWidth="200px">
            <LabeledField label="Created At" source="createdAt" />
            <LabeledField label="Updated At" source="updatedAt" />
          </Box>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            Members
          </Typography>
          <ArrayField source="members.items">
            <Datagrid bulkActionButtons={false}>
              <FunctionField
                label={"Name"}
                render={(record) => {
                  return (
                    <a
                      href={`/#/accounts/${record.accountID}/show`}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Typography
                        variant={"body2"}
                        sx={{
                          color: "blue",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {record.account.firstName} {record.account.lastName}
                      </Typography>
                    </a>
                  );
                }}
              />
              <TextField source="account.contactEmail" label="Email" />
              <TextField source="role" label="Role" />
              <TextField source="accountID" label="Account ID" />
            </Datagrid>
          </ArrayField>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            Integrations
          </Typography>
          <IntegrationsList
            sessionID={randomSessionID}
            organizationID={recordId}
            onRemoveIntegration={() => setIsDeleteIntegrationModalOpen(true)}
            onAddIntegration={handleAddIntegrationClick}
          />
        </Box>
      </SimpleShowLayout>

      {/* Modals */}
      <DeleteConfirmationModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
      />
      <IntegrationModal
        open={isIntegrationModalOpen}
        onClose={handleCloseIntegrationModal}
        onConfirm={handleConfirmAddIntegration}
      />
      <DeleteConfirmationModal
        label={"Confirm deleting integration"}
        model={"integration"}
        open={isDeleteIntegrationModalOpen}
        onClose={() => setIsDeleteIntegrationModalOpen(false)}
        onConfirm={handleRemoveIntegration}
      />
    </Show>
  );
};

const IntegrationsList = ({
  organizationID,
  onRemoveIntegration,
  onAddIntegration,
  sessionID,
}: {
  organizationID: string;
  onRemoveIntegration: (record: { integrationID: string }) => void;
  onAddIntegration: () => void;
  sessionID: string;
}) => {
  const {
    data: pipeline,
    isLoading,
    error,
  } = useGetOne("pipelineForOrganization", {
    id: organizationID,
    meta: {
      tracing: {
        pageID: "admin.showOrganization.integrationList",
        sessionID,
      },
    },
  });

  const data = pipeline?.integrations || [];

  if (isLoading) return <CircularProgress size={25} />;
  if (error)
    return <Typography color="error">Error loading integrations</Typography>;

  return (
    <div>
      <Button
        onClick={onAddIntegration}
        label="Add Integration"
        sx={{ mt: 4 }}
      />

      <List>
        {data?.map((record, index) => (
          <ListItem key={record.integrationID || index}>
            <ListItemText primary={record.integrationID} />
            <Button onClick={onRemoveIntegration} label="Remove" />
          </ListItem>
        ))}
      </List>
    </div>
  );
};
