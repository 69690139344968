import { raAuthCognitoEnglishMessages } from "ra-auth-cognito-language-english";
import { mergeTranslations } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";

const allEnglishMessages = mergeTranslations(
  englishMessages,
  raAuthCognitoEnglishMessages
);

export const i18nProvider = polyglotI18nProvider(
  (locale) => allEnglishMessages,
  "en",
  { allowMissing: true }
);
