import { useEffect, useState } from "react";
import { Button, useDataProvider } from "react-admin";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField as TextFieldMUI,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const IntegrationModal = ({ open, onClose, onConfirm }: any) => {
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [configValues, setConfigValues] = useState<
    { key: string; value: string }[]
  >([]); // Always an array

  const dataProvider = useDataProvider();
  const [integrations, setIntegrations] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await dataProvider.getList("integrations", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      });
      setIntegrations(data);
    })();
  }, [dataProvider]);

  const handleIntegrationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedIntegration(e.target.value);
    setConfigValues({}); // Reset config values when changing integration
  };

  const handleConfirm = () => {
    onConfirm(selectedIntegration, configValues);
  };

  const focusedIntegration = integrations?.find(
    (i) => i.id === selectedIntegration
  );

  const isFormComplete =
    selectedIntegration &&
    focusedIntegration?.configurationTemplate?.every(
      (config: any) =>
        Array.isArray(configValues) &&
        configValues.some(
          (item) =>
            item.key === config.key && item.value && item.value.trim() !== ""
        )
    );

  const handleConfigValueChange = (configKey: string, newValue: string) => {
    setConfigValues((prevValues) => {
      const values = Array.isArray(prevValues) ? prevValues : []; // Ensure prevValues is an array
      const existingIndex = values.findIndex((item) => item.key === configKey);

      if (existingIndex >= 0) {
        // Update the value for an existing key
        const updatedValues = [...values];
        updatedValues[existingIndex] = {
          ...updatedValues[existingIndex],
          value: newValue,
        };
        return updatedValues;
      } else {
        // Add a new key-value pair if not already in the array
        return [...values, { key: configKey, value: newValue }];
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add Integration</DialogTitle>
      <DialogContent>
        <Typography>Select an integration to add:</Typography>
        <TextFieldMUI
          select
          label="Integration"
          value={selectedIntegration}
          onChange={handleIntegrationChange}
          margin="normal"
          helperText="Please select your integration"
        >
          {integrations?.map((integration: any) => (
            <MenuItem key={integration.id} value={integration.id}>
              {integration.name}
            </MenuItem>
          ))}
        </TextFieldMUI>
        {focusedIntegration?.configurationTemplate?.map((config: any) => {
          const value = Array.isArray(configValues)
            ? configValues.find((item) => item.key === config.key)?.value || ""
            : "";
          return (
            <Box key={config.key} display="flex" alignItems="center" mt={2}>
              <TextFieldMUI
                fullWidth
                label={config.label}
                value={value}
                onChange={(e) =>
                  handleConfigValueChange(config.key, e.target.value)
                }
                margin="normal"
              />
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} label="Cancel" />
        <Button
          onClick={handleConfirm}
          label="Add Integration"
          disabled={!isFormComplete}
        />
      </DialogActions>
    </Dialog>
  );
};
