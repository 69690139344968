import {
  Datagrid,
  Empty,
  List,
  Pagination,
  ShowButton,
  TextField,
} from "react-admin";

export const OrganizationsList = () => {
  return (
    <List
      empty={<Empty />}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[50, 100]} />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="organizationID"
          label={"organizationID"}
          sortable={false}
        />
        <TextField source="name" sortable={false} />
        <TextField source="publicID" label={"publicID"} sortable={false} />
        {/*<TextField*/}
        {/*  source="creatorAccountID"*/}
        {/*  label={"creatorAccountID"}*/}
        {/*  sortable={false}*/}
        {/*/>*/}
        <TextField source="status" sortable={false} />
        <TextField source="demo" sortable={false} />
        <TextField source="createdAt" sortable={false} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
